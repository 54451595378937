@import 'src/app/components/mixins.scss';

.section-padding {
  &__sides {
    padding: 0 24px;
  }

  &__topBottom {
    padding: 24px 0;
  }

  &__main {
    padding: 24px;
  }
}

.base-font {
  font-family: $base-font-family;
}

.dialog-header {
  padding: 1rem 0;
  background-color: white;
}

.button-holder {
  padding: 1rem 0 24px;
  width: 100%;
  background-color: white;

  &.share {
    padding: 0 0 24px;
  }

  &.bottom {
    margin-bottom: 24px;
  }

  &.space-between {
    display: flex;
    justify-content: space-between;
  }
}

.apply-btn {
  color: $white;
  background-color: $purple;

  &:disabled {
    background-color: $gray-4;
    border-color: $gray-4;
    cursor: not-allowed;
  }
}

.mat-dialog-actions {
  margin-bottom: 0;
  padding: 0;
}

::ng-deep .mat-dialog-container {
  padding: 0;
}

.share-dialog .mat-dialog-container {
  color: #495179;
}

@media screen and (max-width: 1050px) {
  .payment-dialog .mat-dialog-container {
    padding: 2em 2em;
  }
}

.stripe-products-dialog-container .mat-dialog-container {
  padding: 1rem !important;
  margin: 0 !important;
  background-color: #50237e;
  overflow-y: auto;
}

@media screen and (max-width: 400px) {
  .payment-dialog .mat-dialog-container {
    min-width: 200px;
  }
}
