@import 'src/app/components/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  "CORE" STYLES */
* {
  box-sizing: border-box;
}

:root {
  --kit-color-primary: $gray-6;
  --kit-font-family: 'Mukta', sans-serif;
}

html {
  font-size: 15px;

  @media (max-width: $sm-max-width) {
    font-size: 14px;
  }
}

body {
  font-size: 1rem;
  line-height: 1.5;
  font-family: $base-font-family;
  color: $text;
  overflow-x: hidden;
  position: relative;
  font-variant: normal;
  font-feature-settings: normal;
}

a {
  text-decoration: none;
  color: $text;
  @include transition-color();

  &:hover,
  &:active,
  &:focus {
    color: $blue;
    text-decoration: none;
  }
}

input {
  outline: none !important;
  font-family: $base-font-family;
  color: $text;
}

button,
input {
  outline: none !important;
}

textarea {
  padding: 1.5rem 0.5rem 1rem !important;

  &.no-title {
    padding: 0.5rem 0 !important;
  }
}

// START of angular material v15 styles overriding

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-text-field--disabled {
  background-color: rgba(255, 255, 255, 0) !important;
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(148, 148, 148, 0.38);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  &.base-font {
    color: rgb(226, 226, 226) !important;
    &.text-dark {
      color: rgb(56, 56, 56) !important;
    }
  }

  &.tags-list,
  &.playlist-name {
    color: rgb(226, 226, 226) !important;
    &.text-dark {
      color: rgb(56, 56, 56) !important;
    }
  }
}

.mat-mdc-list-item-unscoped-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mat-mdc-form-field-icon-suffix {
  display: flex !important;
  align-items: baseline !important;
}

.mat-mdc-form-field-infix {
  padding: 0 0.5rem 0.4rem !important;
  display: flex;
  align-items: flex-end;

  .mdc-text-field__input {
    font-size: 14px !important;
  }
}

.mat-mdc-tab .mdc-tab__content {
  width: 100%;
}

.mat-mdc-tab .mdc-tab__text-label {
  color: white !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: rgb(255, 251, 0);
}

.mat-mdc-tab:first-child {
  .mdc-tab__text-label {
    padding: 0 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      &:host:not(:has(.custom)) {
        background-color: #6011cf;
        color: #333b47 !important;
      }
    }
  }

  // &::after {
  //   content: "";
  //   display: block;
  //   background-color: rgb(158, 158, 158);
  //   padding: 0;
  //   margin: 0 8px;
  //   width: 1px;
  //   position: relative;
  // }
}

.mat-mdc-radio-button .mdc-radio {
  padding: 5px !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: 5px !important;
  left: 5px !important;
  width: 20px !important;
  height: 20px !important;
}

.mdc-dialog .mdc-dialog__content {
  padding: 1px !important;
  overflow-x: hidden;

  &.schedule {
    padding: 5px 0 !important;
  }
}

// mat-slider style overriding

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #d80085;
  --mdc-slider-focus-handle-color: #d80085;
  --mdc-slider-hover-handle-color: #d80085;
  --mdc-slider-active-track-color: #d80085;
  --mdc-slider-inactive-track-color: #d80085;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #d80085;
  --mat-mdc-slider-ripple-color: #d80085;
  --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2);
}

.mat-mdc-slider .mat-ripple .mat-mdc-slider-focus-ripple,
.mat-mdc-slider .mat-ripple .mat-mdc-slider-active-ripple {
  background-color: #ac000013 !important;
}

.mat-mdc-dialog-content {
  height: 100%;
}

.mdc-text-field {
  padding: 0 1px 0 3px !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #9850fd;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: rgb(177, 177, 177);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: whitesmoke !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgb(110, 110, 110) !important;
}

.mdc-dialog__title {
  padding: 0 0 1rem !important;
}

.mdc-evolution-chip-set {
  &.saved-searches {
    margin: 10px 8px 0 !important;
  }
}

// dropdown bg menu color
.mdc-menu-surface {
  // background-color: #404856;
}

// ********* Angular Material CHECKBOXES ********* //

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
  ~ .mdc-checkbox__background {
  border-color: rgb(199, 199, 199) !important;
}

.dark-checkbox {
  .mat-pseudo-checkbox-full {
    border: 2px solid rgba(29, 29, 29, 0.589) !important;
  }
}

.mat-pseudo-checkbox-full {
  border: 2px solid rgba(255, 255, 255, 0.589) !important;

  &.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
    border-color: rgba(0, 0, 0, 0) !important;
  }
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #d80085;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mdc-list-item__primary-text {
  // color: rgb(199, 199, 199);
}

.mat-mdc-checkbox.mat-accent {
  &.text-dark * {
    color: rgb(56, 56, 56) !important;
  }
  &.text-light * {
    color: rgb(199, 199, 199) !important;
  }
}

.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgb(179, 179, 179);
}

.mat-mdc-radio-button .mdc-form-field {
  color: rgb(199, 199, 199);
}

.mdc-tab {
  padding: 0 !important;
}

// ********* Angular Material Expansion Panel ********* //

.mat-expansion-panel {
  background-color: $gray-dark-2 !important;
  color: rgb(199, 199, 199);
  box-shadow: none !important;
  border-radius: 0 !important;

  .mat-expansion-panel-header.mat-expanded {
    height: 48px;
  }

  .mat-expansion-panel-header {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-family: var(--kit-font-family);
    font-size: 1rem;
    // padding-left: 0;
  }

  .mat-expansion-indicator::after {
    color: $gray-4;
  }

  .mat-expansion-panel-header-title {
    color: $gray-4;
  }
}

// ********* Angular Material Expansion Panel END ********* //

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: $gray-2;
}

.mat-button-toggle-label-content {
  line-height: 32px !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgb(126, 126, 126);
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(156, 156, 156) !important;
}
// END of angular material v15 form-field styles overriding

input[type='text'],
input[type='password'],
input[type='email'],
input[type='search'],
textarea {
  appearance: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark-gray-blue;
}

svg {
  vertical-align: initial;
  overflow: auto;
}

.badge-example {
  font-size: rem(14);
  text-transform: uppercase;
  margin-bottom: rem(15);
  background: $gray-2;
  color: $black;
  display: inline-block;
  padding: rem(3) rem(6);
  border-radius: 4px;
}

perfect-scrollbar > .ps.ps--active-y > .ps__rail-y {
  background-color: transparent !important;
}

// dark theme
[data-theme='dark'] {
  body {
    background: $dark-gray-6 !important;

    .mat-mdc-pseudo-checkbox:not(.mat-mdc-pseudo-checkbox-checked),
    .mat-mdc-checkbox-frame,
    .mat-mdc-checkbox .mdc-form-field,
    .mdc-checkbox,
    .mat-mdc-button.mat-unthemed,
    .mat-mdc-checkbox
      .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background,
    input {
      color: $dark-gray-1 !important;
      border-color: $dark-gray-1 !important;
    }

    .badge-example {
      background: $dark-gray-4;
    }
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    &.base-font {
      color: rgb(226, 226, 226) !important;
      &.text-dark {
        color: rgb(226, 226, 226) !important;
      }
    }

    &.tags-list,
    &.playlist-name {
      color: rgb(226, 226, 226) !important;
      &.text-dark {
        color: rgb(226, 226, 226) !important;
      }
    }
  }

  .mat-mdc-menu-panel,
  .mat-mdc-select-panel {
    background-color: $dark-gray-4;

    .mat-mdc-menu-item,
    .mdc-list-item__primary-text,
    .mat-mdc-option {
      color: $dark-gray-2;

      i,
      mat-mdc-icon,
      .mat-icon-no-color,
      .mat-mdc-menu-submenu-icon {
        color: $dark-gray-1 !important;
      }
    }
  }

  mat-icon-button,
  .mat-mdc-icon-button {
    color: $gray-6 !important;
  }

  .mat-mdc-slide-toggle .mdc-form-field {
    color: $gray-5 !important;
  }

  .mat-mdc-outlined-button.mat-unthemed {
    color: $gray-5 !important;
  }

  .mat-mdc-outlined-button:not(:disabled) {
    border-color: $gray-6 !important;
  }

  .mat-mdc-slide-toggle .mdc-switch--disabled + label {
    color: $gray-6 !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    color: $dark-gray-2 !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: $dark-gray-5;

    .button-holder,
    .dialog-header {
      background-color: $dark-gray-5;
    }

    p {
      color: $dark-gray-2;
    }

    .dialog__close-btn,
    .cancel-btn {
      background-color: $dark-gray-4;
      color: $dark-gray-2;

      &:hover {
        color: $dark-gray-1;
      }
    }

    .back-btn,
    .continue-btn {
      color: $dark-gray-2;

      &.mat-mdc-stroked-button:not(.mat-mdc-button-disabled),
      &.mat-mdc-button.mat-mdc-button-base {
        border: 1px solid $dark-gray-2;
      }
    }

    .next-btn,
    .copy-link-btn {
      color: $dark-gray-6;

      &.mat-mdc-raised-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
        background-color: $gray-5;
        color: $gray-6;
      }
    }

    .mat-mdc-dialog-actions {
      .status {
        color: $dark-gray-1;
      }
    }

    .aspect-ratio-container {
      .status {
        color: $dark-gray-1;
      }
    }

    .zoom-integration-container {
      .status > span:first-child {
        color: $dark-gray-2;
      }
    }

    .apply-btn {
      color: $dark-gray-6;

      &.mat-mdc-raised-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
        background-color: $gray-5;
        color: $gray-6 !important;
      }
    }

    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label,
    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: $gray-6;
    }

    .mat-mdc-form-field.mat-focused .mat-mdc-form-field-label,
    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: #3f51b5;
    }

    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
      background-color: $gray-6;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
      border-bottom-color: $gray-6;
    }
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #6776c7;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: #6776c7;
  }

  .bio {
    color: #6776c7 !important;
  }

  .mat-divider {
    border-top-color: $gray-5 !important;
  }

  .counter {
    color: $gray-6 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dark-gray-1 !important;
  }

  a {
    color: $dark-gray-1;

    &:hover,
    &:active,
    &:focus {
      color: $gray-5;
      text-decoration: none;
    }
  }
}

// custom scrollbar

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-right: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #404856;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
  opacity: 0.9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(190, 190, 190);
}

.grecaptcha-badge {
  visibility: hidden !important;
  bottom: 5rem !important;
}
